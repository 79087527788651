
import Card from '@/components/cards/Card.vue'
import Badge from '@/components/status/Badge.vue'
import { OfferReadDto } from 'tradingmate-modules/src/models/api/offers'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    Card,
    Badge
  }
})
export default class OfferCard extends Vue {
  @Prop({ required: true })
  private readonly offer!: OfferReadDto

  get thumbnail (): string {
    const thumb = '/images/brand/placeholder.jpg'
    return thumb
  }
}
