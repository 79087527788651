
import OfferCard from './OfferCard.vue'
import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'
import { Cards } from '@/components/cards'
import Paging from '@/components/actions/Paging.vue'
import InfoState from '@/components/status/InfoState.vue'
// import { OfferReadDto, OfferStatus } from 'tradingmate-modules/src/models/api/offers'
// import OfferFilter from 'tradingmate-modules/src/filters/OfferFilter'
import BlogFilter from 'tradingmate-modules/src/filters/BlogFilter'
import { BlogModel, BlogPostStatus } from 'tradingmate-modules/src/models/api/blogs'
import BlogType from 'tradingmate-modules/src/models/api/blogs/BlogType'
import BlogCard from '@/components/cards/BlogCard.vue'

@Component({
  components: {
    BlogCard,
    Cards,
    OfferCard,
    Paging,
    InfoState
  }
})
export default class OfferIndex extends Vue {
  private pagedData: PagedResultSet<BlogModel> | null = null

  private filter: BlogFilter = new BlogFilter({
    BusinessBranchId: this.$store.state.identity.BusinessBranch.BusinessBranchId,
    Status: BlogPostStatus.None,
    BlogType: BlogType.Offer
  })

  private hasErrors = false
  private gettingOffers = false

  mounted (): void {
    this.GetOffers()
  }

  private GetOffers (): void {
    this.gettingOffers = true
    this.hasErrors = false
    Services.API.Blogs.GetBlogsForConsole(this.filter)
      .then((offers) => {
        this.pagedData = offers
      }).catch(() => {
        this.hasErrors = true
      }).finally(() => {
        this.gettingOffers = false
      })
  }
}
